let shadowRoot;
window.addEventListener('heyflow-init', () => {
    setTimeout(()=>window.dispatchEvent(new CustomEvent('heyflow-modal-element:open', {
        detail: {
            modalId: 'jdtdb4JGu3Q6jrIMYQOGW'
        }})), 2000)

    shadowRoot =
    (document.querySelector(`[flow-id="${event.detail.flowID}"]`) &&
        document.querySelector(`[flow-id="${event.detail.flowID}"]`)
        .shadowRoot) ||
    document;
    shadowRoot.querySelector("#input-97854b9c").heyflowOnAutocomplete = async (text, update) => {
        const results = await (await fetch(`https://www.omdbapi.com?apikey=21136a97&s=${text}`)).json();
        console.log(results)
        const items = results.Search.map(({
            Title
        }) => ({
            label: Title
        }));
        update(items);
    }
})